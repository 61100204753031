<template>
  <div class="resetPassword">
    <div class="page">
      <div class="Section0" style="layout-grid:15.6000pt;">
        <h1 align="center" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
 text-align:center; margin-bottom: 20px;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:24.0000pt;mso-font-kerning:22.0000pt;">
              <font face="微软雅黑">用户协议与隐私条款</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:24.0000pt;mso-font-kerning:22.0000pt;">
              
            </span></b></h1>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:13.5000pt;mso-font-kerning:1.0000pt;">
            <font face="微软雅黑">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:13.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">用户协议与隐私条款（以下简称</font>
            <font face="黑体">“本协议”）是</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">上海玉漭物流科技有限公司</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">（以下简称</font>
            <font face="黑体">“公司”）及其关联方与用户（以下简称“您”），基于服务所订立的有效合约。请您在接受本协议前仔细阅读本协议中的全部内容，确保充分理解本协议中的各条款。</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">您通过网络</font>
            <font face="黑体">
              /手机页面点击确认或以其他方式选择接受本协议，即表示您与公司已达成协议并同意接受本协议的全部约定内容、以及与本协议有关的各项规则。如您未满18周岁，请您在法定监护人陪同下仔细阅读并充分理解本协议，并征得法定监护人的同意后接受本协议。
            </font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">公司尊重并保护用户信息，并且将以高度勤勉和审慎的态度对待这些信息。在您使用了公司服务网站提供的服务时，我们将按照本隐私政策收集、处理及披露您的信息。本政策将帮助您了解以下内容：
            </font>
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">一、</font>
            <font face="黑体">适用范围</font>
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">二、</font>
            <font face="黑体">用户信息的收集与使用</font>
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">三、</font>
            <font face="黑体">我们如何使用</font>
            <font face="黑体">Cookie 及相关技术&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">四、</font>
            <font face="黑体">用户信息的共享、转让和公开披露</font>
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">五、</font>
            <font face="黑体">用户业务数据</font>
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">六、</font>
            <font face="黑体">用户信息的管理</font>
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">七、</font>
            <font face="黑体">用户信息的安全</font>
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">八、</font>
            <font face="黑体">未成年人信息的保护</font>
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">九、</font>
            <font face="黑体">您的权利</font>
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">十、</font>
            <font face="黑体">法律声明及隐私政策的更新</font>
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">十一、</font>
            <font face="黑体">如何联系我们</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <h3 style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="黑体">一、</font>
              <font face="黑体">适用范围</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              
            </span></b></h3>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">1.1 本隐私政策适用于公司官网、</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">业务网站、</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">服务号</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">、</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">小程序</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">等所有服务。服务包括向您提供页面浏览、网站登录服务，以及通过公司官方网站或公司技术人员通过非官网渠道向您提供的技术服务。</font>
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">1.2 我们的服务可能包含指向第三方网站、应用程序或服务的链接，本隐私政策不适用于第三方提供的任何产品、服务、网站或内容。&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">1.3 需要特别说明的是，作为公司官方服务的用户，若您利用公司的官网服务，为您的用户再行提供服务，因您的业务数据属于您所有，您应当另行与您的用户约定隐私政策。</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <h3 style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="黑体">二、</font>
              <font face="黑体">用户信息的收集和使用</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              
            </span></b></h3>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">2.1 用户信息的收集&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">2.1.1 当您在公司官方服务网站</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">、</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">服务号</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">、</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">小程序</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">创建账户时，您须向我们提供注册邮箱</font>
            <font face="黑体">
              /手机号，设置、确认您的登录密码，您提供的注册邮箱/手机号为您的官网服务账户，您提交的手机号码/邮箱用于您注册、登录、绑定账户、密码找回时接受验证码，并且作为您与公司指定的联系方式之一，用来接受相关业务通知（如
            </font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">新产品</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">上线、服务变更、其他</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">业务</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">运行</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">过程中可能产生的任何</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">信息通知等），并且公司可通过此联系方式向您发送业务通知（含账单）、宣传产品、营销推广或与您进行业务沟通。</font>
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">2.1.2 在您注册完成官方服务账户后，您可以在控制台修改个人消息，包括手机号（“消息接收人手机号码”）、邮箱、电话等信息。</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">2.1.2 在您注册完成公司官网服务账户后，您可以申请</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">认证成为企业用户</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">，若申请应完成</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">企业</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">
              实名认证，进行实名认证为《中华人民共和国网络安全法》所规定的您的应尽义务。您需向公司提交您的企业注册地区、企业性质、企业名称和营业执照注册号，以及银行账户信息（包括开户行、银行账号和开户人）公司将以您提交的企业注册地区、企业性质、企业名称和营业执照注册号确认您的实名信息，您提供的银行账户信息（包括开户行、银行账号和开户人）仅作为公司完成您的实名认证时的打款账号使用。
            </font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">2.1.</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">3</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;<font face="黑体">在您使用服务过程中，我们会根据您使用</font></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">服务所在的环境</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">，接收并记录您所使用的设备相关信息（例如设备型号、操作系统版本、设备设置、唯一设备标识符等软硬件特征信息）、设备所在位置相关信息（例如</font>
            <font face="黑体">IP地址、GPS位置以及能够提供相关信息的Wi-Fi接入点、蓝牙和基站等传感器信息）。我们可能会将前面两类信息进行关联，以便我们能在不同设备上为您提供一致的服务。</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">2.1.</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">4</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;<font face="黑体">
              当您使用我们的网站提供的服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的搜索查询内容、</font>
            <font face="黑体">IP地址、浏览器的类型、电信运营商、使用的语言、访问日期和时间及您访问的网页记录等。&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">2.1.</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">5</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;<font face="黑体">
              请您注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息的。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。
            </font>
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">2.1.</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">6</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;<font face="黑体">
              基于您使用公司官网服务而产生的用户的咨询记录、报障记录和针对用户故障的排障过程（如通信或通话记录），公司将通过记录、分析这些信息以便更及时响应您的帮助请求，以及用于改进服务。</font>
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
              <font face="黑体">2.2. 我们出于如下目的，使用您提交以及我们收集的用户信息：&nbsp;</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
              
            </span></b></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">2.2.1 为了向您提供产品或服务（如实名认证信息、用来接收验证码的手机号是继续获得服务的前提）；&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">2.2.2 为了维护、改进产品或服务（如您提交的业务联系人手机、电子邮件等用来接收业务通知和进行业务沟通）；&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">2.2.3
              我们可能以用户信息统计数据为基础，设计、开发、推广全新的服务；我们会对我们的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，但这些统计信息不包含您的任何身份识别信息&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">2.2.4 在法律法规允许的前提下，向您推广、介绍</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">服务</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">；</font>
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">2.2.5
              为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或公司云服务相关协议、规则的情况，我们可能使用您的账户信息、并整合设备信息、有关网络日志以及我们关联公司、合作伙伴分享的信息，来判断您账户及交易风险、进行身份验证、安全事件的检测及防范，并依法采取必要的记录、审计、分析、处置措施；&nbsp;
            </font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">2.2.6 经您许可的其他用途。</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <h3 style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="黑体">三、</font>
              <font face="黑体">我们如何使用</font>
              <font face="黑体">Cookie及相关技术</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              
            </span></b></h3>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">3.1 我们可能会通过 Cookie 和其他相关技术收集和使用您的信息。我们使用 Cookie 的具体用途包括：&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">3.1.1 记住您的身份。例如：Cookie 有助于我们辨认您作为我们的注册用户的身份。&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">3.1.2 分析您使用我们服务的情况，以便为您提供更加周到的个性化服务，包括但不限定制化页面、推荐等。&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">3.2 您可以通过浏览器设置拒绝或管理 Cookie。但请注意，如果停用 Cookie，您有可能无法享受优质的服务体验，某些功能的可用性可能会受到影响。&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">3.3 我们不会将 Cookies 用于本声明和政策所述目的之外的任何用途。</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <h3 style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="黑体">四、用户信息的共享、转让和公开披露</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              
            </span></b></h3>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
              <font face="黑体">4.1 共享&nbsp;</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
              
            </span></b></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
              <font face="黑体">我们不会与其他组织和个人共享您的用户信息，但以下情况除外：</font>
              <font face="黑体">&nbsp;</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
              
            </span></b></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">4.1.1 获得您的明确同意后，我们会与其他方共享您的用户信息；&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">4.1.2 我们可能会根据法律法规规定、诉讼、仲裁解决需要，或按行政、司法机关依法提出的要求，对外共享您的用户信息；&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">4.1.3
              关联公司共享：您的用户信息可能会与公司的关联公司共享。我们只会共享提供服务所必要的用户信息，且受本隐私政策中所声明目的的约束。关联公司如欲改变用户信息的处理目的，将再次征求您的授权同意。&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">4.1.4
              我们可能委托受信赖的合作伙伴来提供服务，因此我们可能会与合作伙伴共享您的某些用户信息，以提供更好的客户服务和优化用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的用户信息，并且只会共享提供服务所必要的用户信息。我们的合作伙伴无权将共享的用户信息用于任何其他用途。
              目前，我们的授权合作伙伴包括如下类型：供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供用户认证服务、基础技术服务、提供咨询、分析等专业服务。
              对我们与之共享用户信息的公司、组织和个人，我们会与其签署严格的保密协议或在协议保密条款中约定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理用户信息。&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
              <font face="黑体">4.2 转让&nbsp;</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
              
            </span></b></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
              <font face="黑体">我们不会将您的用户信息转让给任何公司、组织和个人，但以下情况除外：</font>
              <font face="黑体">&nbsp;</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
              
            </span></b></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">4.2.1 获得您的明确同意后，我们会向其他方转让您的用户信息；&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">4.2.2
              在公司与其他法律主体者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到用户信息转让，我们会要求新的持有您用户信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。&nbsp;
            </font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
              <font face="黑体">4.3 公开披露&nbsp;</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
              
            </span></b></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
              <font face="黑体">我们仅会在以下情况下，公开披露您的用户信息：</font>
              <font face="黑体">&nbsp;</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
              
            </span></b></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">4.3.1 获得您明确同意或基于您的主动选择，我们可能会公开披露您的用户信息；&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">4.3.2 为保护公司官方平台及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据适用的法律或公司官方平台相关协议、规则披露关于您的用户信息。&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
              <font face="黑体">4.4 共享、转让、公开披露用户信息时事先征得授权同意的例外&nbsp;</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
              
            </span></b></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">以下情形中，共享、转让、公开披露您的用户信息无需事先征得您的授权同意：</font>
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">4.4.1 与国家安全、国防安全有关的；&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">4.4.2 与公共安全、公共卫生、重大公共利益有关的；&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">4.4.3 与犯罪侦查、起诉、审判和判决执行等有关的；&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">4.4.4 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">4.4.5 您自行向社会公众公开的个人信息；&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">4.4.6 与您履行法律法规规定的义务相关的；&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">4.4.7 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <h3 style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="黑体">五、用户业务数据</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              
            </span></b></h3>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">
              5.1您在使用公司官网服务过程中以分析、加工、存储、上传、下载、分发以及通过其他方式处理的业务数据，均为您的用户业务数据，您完全拥有您的用户业务数据。公司作为服务提供商，我们只会严格执行您的指示处理您的业务数据，除按与您协商一致或执行明确的法律法规要求外，不对您的业务数据进行任何非授权的使用或披露。&nbsp;
            </font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">5.2
              公司提示您谨慎判断用户业务数据来源及内容的合法性：您应确保用户业务数据是依法收集、使用（包括但不限于委托处理等）的，您不会也不曾以任何方式侵犯任何个人或实体的合法权利。您理解并同意，中国及其他国家、地区可能对数据存储、出境等有相关的规定，在您使用公司产品服务对用户业务数据进行存储、传输等操作前，您应当遵照相关地区适用的法律法规进行充分、必要的评估、审批，确保符合相关规定，否则您将自行承担全部结果及责任。因您的用户业务数据来源、内容或处理违反法律法规、部门规章或国家政策而造成的全部结果及责任均由您自行承担。&nbsp;
            </font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">5.3 根据您与公司协商一致，公司在您选定的数据中心存储用户业务数据。公司根据适用的法律保护用户存储在公司数据中心的数据，</font>
          </span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
              <font face="黑体">但因您未遵守相关法律法规或产生资源欠费或资源过期的资源被删除导致数据丢失的除外。</font>
            </span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <h3 style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="黑体">六、用户信息的管理</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              
            </span></b></h3>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">6.1
              您可以访问、修改和删除您的用户信息。为保障您的账户安全、合法权益等，不同的信息访问、修改和删除可能有不同的要求，并且，基于技术逻辑、法律法规要求、保障信息安全等正当事由，您的部分信息可能无法访问、修改和删除（例如您无法更改分配的账户
              ID）。&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">6.2 如果您需要变更您公司的实名认证信息，您需通过电话、电子邮件等方式联系公司，按照公司的要求进操作。&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">6.3 在以下情形中，您可以向我们提出删除用户信息的请求：&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">6.3.1 如果我们处理用户信息的行为违反法律法规；&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">6.3.2 如果我们收集、使用您的用户信息，却未征得您的明确同意；&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">6.3.3 如果我们处理个人信息的行为严重违反了与您的约定； 我们可能会先要求您验证自己的身份（您可能需要提供书面请求或以其他方式证明您的身份），然后再处理您的请求。&nbsp;
            </font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">6.3.4
              您可注销此前注册的账户，在注销成功后，我们将停止为您提供产品或服务，该账户下所有服务、数据将被删除且无法恢复。另外，我们将依据您的要求，删除您的个人信息或对其进行匿名化处理，法律法规另有规定的除外。</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <h3 style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="黑体">七、用户信息的安全</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              
            </span></b></h3>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">7.1
              公司非常重视您的信息安全。我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的用户信息。防止用户信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会使用加密技术提高用户信息的安全性；我们会使用受信赖的保护机制防止用户信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问用户信息。&nbsp;
            </font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
              <font face="黑体">7.2
                我们会采取合理可行的措施，尽力避免收集无关的用户信息。我们只会在达成本政策所述目的所需的期限内保留您的用户信息，除非需要延长保留期或受到法律的允许。超出上述用户信息保存期限后，我们会对您的个人信息进行删除或匿名化处理。&nbsp;
              </font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
              
            </span></b></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">7.3
              请使用复杂密码，协助我们保证您的账户安全。我们将尽力保障您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
            </font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">7.4
              请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网环境下，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。
              在不幸发生用户信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知用户信息主体时，我们会采取合理、有效的方式发布公告。&nbsp;
            </font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">7.5 同时，我们还将按照监管部门要求，上报用户信息安全事件的处置情况。&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">7.6
              我们会按照相关法律法规的规定，将在中国大陆收集到的您的用户信息存放在中国大陆境内，如在符合适用法律规定的情形下因业务需要向境外传输用户信息的，我们会事先征得您的同意，并向您告知用户信息出境的目的、接收方、安全保障措施、安全风险等情况。&nbsp;
            </font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">7.7
              如出现公司产品和服务停止运营的情形，我们会采取合理措施保护您用户信息安全，包括及时停止继续收集用户信息的活动；停止运营的通知将以逐一送达或公告的形式通知用户；并对所持有的个人信息进行删除或匿名化处理等。</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <h3 style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="黑体">八、未成年人信息的保护</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              
            </span></b></h3>


      </div>
      <div class="Section0" style="layout-grid:15.6000pt;">
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
              <font face="黑体">8.1
                我们主要面向成人提供产品和服务。如您为未成年人，我们要求您请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。&nbsp;</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;">
              
            </span></b></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">8.2 对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
            </font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <h3 style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              <font face="黑体">九、您的权利</font>
            </span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
              
            </span></b></h3>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</font>
            <font face="黑体">&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">9.1
              您有权通过登录您在公司官方服务网站的账户来访问您的用户信息，法律法规规定的例外情况除外。如果您无法正常访问用户信息，您可联系公司官方服务网站预留的客服电话，我们将在30天内回应您的访问请求。对于您在使用我们的产品或服务过程中产生的其他用户信息，在合理的技术手段与成本支出范围内，我们会向您提供此类信息。&nbsp;
            </font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">9.2 更正您的用户信息</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">。</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">当您发现我们处理的关于您的用户信息有错误时，您有权要求我们作出更正。您可联系公司官方服务网站预留的客服电话，我们将在</font>
            <font face="黑体">30天内回应您的更正请求。&nbsp;</font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            <font face="黑体">为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</font>
            <font face="黑体">
              我们将在三十天内作出答复。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
            </font>
          </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
            
          </span></p>
        <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
          <font face="黑体">&nbsp;</font>
        </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
          
        </span></p>
      <h3 style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="黑体">十、法律声明及隐私政策的更新</font>
          </span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            
          </span></b></h3>
      <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
          <font face="黑体">
            我们可能适时修订法律声明及隐私政策的条款，该等修订构成本声明和政策的一部分。如可能造成您在本声明和政策下权利的实质减少，或扩大我们收集、使用信息的范围等重要规则的变更，我们将在修订生效前通过在公司网站上显著位置提示或向您发送电子邮件或以其他方式通知您。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本声明和政策的约束。
          </font>
        </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
          
        </span></p>
      <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
          <font face="黑体">&nbsp;</font>
        </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
          
        </span></p>
      <h3 style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            <font face="黑体">十一、如何联系我们</font>
          </span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
 font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;">
            
          </span></b></h3>
      <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
          <font face="黑体">您对本声明和政策内容有任何疑问和意见，或者您对公司对本声明和政策的实践操作上有任何疑问和意见，您可通过客服电话或寄送材料与我们联系</font>
          <font face="黑体">:&nbsp;</font>
        </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
          
        </span></p>
      <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 margin-left:0.0000pt;mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
 text-indent:0.0000pt;mso-pagination:widow-orphan;"></p>
 <p class="p" style="margin-top:5.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
 mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
          <font face="黑体">邮寄地址：上海市杨浦区荆州路168号安联大厦509室 </font><br>
          <font face="黑体">我们将尽快审核所涉问题，并在验证您的用户身份后的三十天内予以回复。</font>
        </span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
 letter-spacing:0.0000pt;text-transform:none;font-style:normal;
 font-size:10.5000pt;mso-font-kerning:1.0000pt;">
          
        </span></p>
    </div>
  </div>
</div></template>
<script>

export default {
  components: {},
  data() {
    return {

    };
  },
  methods: {
  },
};
</script>
<style lang="less" scoped>.resetPassword {
  padding: 30px;
  min-height: 84vh;

  .page {
    width: 900px;
    margin: 0 auto;
  }
}</style>